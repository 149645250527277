import React from "react";
import PropTypes from "prop-types";
import { FaLinkedinIn } from "react-icons/fa";

const HoverImage = ({ content, name, position, linkedinUrl, image }) => {
  return (
    <section className="pb-4 w-full h-96 mx-2 text-[#4d4d4d] text-sm lg:text-xs xl:text-base">
      <div className="group bg-primary-yellow-dark rounded-xl h-[95%]  relative">
        <div className="block group-hover:hidden h-full relative">
          <img src={image} alt="image" className="h-full" />
        </div>
        <div className="bg-primary-yellow-dark hidden group-hover:block relative h-full rounded-xl">
          <div className="p-5 lg:px-4 lg:py-3">{content}</div>
        </div>
      </div>
      <div className="flex justify-between my-3 pb-6">
        <div>
          <p className="font-bold text-black">{name}</p>
          <p>{position}</p>
        </div>
        <div>
          <a href={linkedinUrl}>
            <FaLinkedinIn color="#0064bf" size="2rem" />
          </a>
        </div>
      </div>
    </section>
  );
};

HoverImage.propTypes = {
  content: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  linkedinUrl: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default HoverImage;
