import React from "react";
import HoverImage from "../../../../components/HoverImage";
import { Finian, Ravee, Naveen } from "../../../../images/index";
import Container from "../../../../utils/Container";

const FoundingTeam = () => {
  return (
    <Container className=" mt-6 mb-12">
      <p className="text-primary-blue-dark font-extrabold text-2xl lg:text-3xl xl:text-5xl my-6">Our founding team</p>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-9">
        <HoverImage
          image={Finian}
          content={
            <>
              <p className=" mb-2">
                Dr. Finian Tan founded Vickers Venture Partners in 2005 with four partners. He is currently based in
                Singapore.
              </p>
              <p className=" mb-2">
                Before starting Vickers, Finian was a Managing Director at Draper Fisher Jurvetson. During his time
                there, he acted as the founding partner of its Asia Pacific Operations.
              </p>
              <p className=" mb-2">
                Through this role, Dr. Finian discovered Baidu, and helped lead all the investment rounds into it,
                remaining its largest backer until its IPO.
              </p>
            </>
          }
          name="Dr.Finian Tan"
          position="Chairman"
          linkedinUrl="https://www.linkedin.com/in/dr-finian-tan-58188888/"
        />
        <HoverImage
          image={Ravee}
          content={
            <>
              <p className=" mb-2">
                Having led performance improvement programs in number of multinationals and governments across the
                globe, Ravee is passionate about helping organizations perform better.
              </p>
              <p className=" mb-2">
                His leadership career and deep research in the subject of performance management for the past two
                decades has shaped a revolutionary cloud app in the domain of business performance management, which is
                now emerging in the market via entomo.
              </p>
            </>
          }
          name="Ravee Ramamoothie"
          position="Founder & Group CEO"
          linkedinUrl="https://www.linkedin.com/in/ravee-ramamoothie-3b756b24/"
        />
        <HoverImage
          image={Naveen}
          content={
            <>
              <p className=" mb-2">
                Technology and business development executive with strategic and tactical expertise in business
                intelligence, product and solution engineering, and client partnerships.
              </p>
              <p className=" mb-2">
                In his multifaceted leadership role, Naveen contributes extensively to critical business decisions for
                entomo as it scales and expands globally.
              </p>
            </>
          }
          name="Naveen Deshpande"
          position="Co Founder & Group COO"
          linkedinUrl="https://www.linkedin.com/in/pande85/"
        />
      </div>
    </Container>
  );
};

export default FoundingTeam;
