import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Container from "../../../../utils/Container";

const TeamGallery = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allContentfulAsset(
        filter: { title: { in: ["Team 1", "Team 2", "Team 3", "Team 4", "Team 5", "Team 6"] } }
        sort: { fields: title }
      ) {
        nodes {
          title
          gatsbyImageData
        }
      }
    }
  `);
  return (
    <Container>
      <div className="mt-[3%] mb-[4%]">
        <p className="text-primary-blue-dark font-extrabold mb-[1.5%] text-center text-2xl sm:text-left sm:text-3xl xl:text-5xl">
          building tomorrow, today!
        </p>
        <div className="lg:grid  lg:grid-cols-12 gap-3">
          <div className="w-full lg:col-span-4">
            <GatsbyImage
              image={data.allContentfulAsset.nodes[0].gatsbyImageData}
              className="lg:h-full w-full lg:w-auto  lg:object-cover my-3"
            />
          </div>
          <div className="lg:grid lg:grid-rows-2 lg:col-span-3 lg:gap-3">
            <div>
              <GatsbyImage
                image={data.allContentfulAsset.nodes[1].gatsbyImageData}
                className="h-full w-full lg:object-cover my-3"
              />
            </div>
            <div>
              <GatsbyImage
                image={data.allContentfulAsset.nodes[2].gatsbyImageData}
                className="lg:h-full w-full h-56 object-cover my-3"
              />
            </div>
          </div>
          <div className="lg:grid lg:grid-rows-2 lg:col-span-5 lg:gap-3">
            <div className="w-full">
              <GatsbyImage
                image={data.allContentfulAsset.nodes[3].gatsbyImageData}
                className="h-full w-full object-contain lg:object-cover my-3"
              />
            </div>
            <div className="lg:grid  lg:grid-cols-2 lg:gap-3">
              <div>
                <GatsbyImage
                  image={data.allContentfulAsset.nodes[4].gatsbyImageData}
                  className="lg:h-full w-full object-cover my-3"
                />
              </div>
              <div>
                <GatsbyImage
                  image={data.allContentfulAsset.nodes[5].gatsbyImageData}
                  className="lg:h-full w-full h-56 object-cover my-3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TeamGallery;
