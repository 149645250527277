import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Banner from "../../../containers/Banner";
import EvolvingEnterprises from "../../../containers/EvolvingEnterprises";
import FoundingTeam from "../../../containers/global/about/our-team/FoundingTeam";
import TeamGallery from "../../../containers/global/about/our-team/TeamGallery";
import Layout from "../../../containers/global/layout";
import CocreatingInspire from "../../../containers/global/about/our-team/CocreatingInspire";
import BreadCrumb from "../../../containers/BreadCrumb";
import Container from "../../../utils/Container";

const breadCrumbItems = [
  {
    text: "home ",
    link: "/",
  },
  {
    text: "about",
    link: "/about",
  },
  {
    text: "our team",
  },
];

const OurTeam = ({ data }) => {
  const banner = data?.banner.nodes[0];
  const evolvingEnterprises = data?.evolvingEnterprises.nodes[0];
  return (
    <Layout>
      <Banner bgImg={banner.desktopImage.gatsbyImageData} mobImg={banner.mobileImage.gatsbyImageData}>
        {
          <div className=" text-center md:text-left absolute top-12 md:top-10 xl:top-12">
            <p className="text-3xl xl:text-5xl font-extrabold mb-2">{banner.title}</p>
            <p className=" text-base md:w-2/3 lg:w-1/2 xl:w-2/5 mx-auto md:mx-0">
              at entomo, we take pride in our diverse teams and global footprint. Together, we are on a mission to
              revolutionize performance for the enterprises of tomorrow.
            </p>
          </div>
        }
      </Banner>
      <Container>
        <BreadCrumb breadCrumbItems={breadCrumbItems} />
      </Container>
      <TeamGallery />
      <CocreatingInspire />
      <FoundingTeam />
      <EvolvingEnterprises
        bgImg={evolvingEnterprises.desktopImage.gatsbyImageData}
        mobImg={evolvingEnterprises.mobileImage.gatsbyImageData}
        title={evolvingEnterprises.title}
        leftText={evolvingEnterprises.leftText}
        rightText={evolvingEnterprises.rightText}
        bottomText={evolvingEnterprises.bottomText}
      />
    </Layout>
  );
};
OurTeam.propTypes = {
  data: PropTypes.object,
};
export default OurTeam;

export const query = graphql`
  query {
    banner: allContentfulBannerContent(filter: { heading: { eq: "Our Team Banner" } }) {
      nodes {
        title
        subTitle
        desktopImage {
          gatsbyImageData
        }
        mobileImage {
          gatsbyImageData
        }
      }
    }
    evolvingEnterprises: allContentfulEvolvingEnterprises {
      nodes {
        desktopImage {
          gatsbyImageData
        }
        mobileImage {
          gatsbyImageData
        }
        title
        leftText
        rightText
        bottomText
      }
    }
  }
`;
