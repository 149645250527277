import React from "react";
import { Speed, Passion, Cultivate, Care, Believe, Critique } from "../../../../images/index";
import Container from "../../../../utils/Container";

const inspires = [
  {
    image: Speed,
    text: "we thrive on speed  deliver promises on time",
  },
  {
    image: Passion,
    text: "we have passion for excellence @ global scale",
  },
  {
    image: Cultivate,
    text: "we cultivate  always act in high self-awareness",
  },
  {
    image: Care,
    text: "we care for our people",
  },
  {
    image: Believe,
    text: "we believe good leadership is the heart of our organisation",
  },
  {
    image: Critique,
    text: "we critique self first  everything else next.",
  },
];

const CocreatingInspire = () => {
  return (
    <Container className=" bg-primary-blue-dark text-white pt-[1%]">
      <p className=" text-center pb-4 text-3xl xl:text-5xl font-extrabold">co-creating true value</p>
      <div className=" flex flex-col lg:flex-row justify-center">
        {inspires.map((inspire) => {
          return (
            <div key={inspire.text} className=" px-4 text-center w-full lg:w-1/6 my-[4%]">
              <img src={inspire.image} alt="inspire image" className=" mx-auto" />
              <p>{inspire.text}</p>
            </div>
          );
        })}
      </div>
      <p className="text-center font-bold mb-7 italic">The importance of inclusion is key to triumph together.</p>
      <p className="text-center pb-[5%] lg:w-4/5 mx-auto italic">
        Our&nbsp; commitment&nbsp;to the&nbsp;Women&nbsp;Empowerment Principles, anchored by UN&nbsp;Women&nbsp;and UN
        Global Compact,&nbsp;ensures good things come to all people, regardless of gender as well as race, colour,
        national origin, disability, age, genetic or medical history.
      </p>
    </Container>
  );
};

export default CocreatingInspire;
